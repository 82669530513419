import { useContext, useState, useEffect } from "react";
import Calendar from "../Components/Common/Calender";
import "./page.css";
import { AppContext } from "../Context/AppContext";

import * as React from "react";
import dayjs from "dayjs";

import { endpoints } from "../services/api";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { TextField } from "@mui/material"; // Import TextField from Material-UI
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";

const scheduleData = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30,
];

const TextFieldComponent = (props) => {
  return <TextField {...props} disabled={true} />;
};

function AutomatePage() {
  const [durationData, setDurationData] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30,
  ]);

  // to 60
  const [variationData, setVarianceData] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
    60,
  ]);

  const [NoticeData, setNoticeData] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
    60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72,
  ]);

  const [durationOption, setDurationOption] = useState([
    "Hours",
    "Days",
    "Weeks",
  ]);

  const { showSidebar } = useContext(AppContext);

  const { accessToken } = useSelector((state) => state.auth);

  const [isAutomate, setIsAutomate] = useState(false);

  const [value, setValue] = React.useState(null);
  // const [value, setValue] = React.useState(dayjs(new Date()));

  const [varianceOption, setVarianceOption] = useState(["Minutes"]);

  const [loading, setLoading] = useState(false);

  const generateArray = (start, end) => {
    const array = [];
    for (let i = start; i <= end; i++) {
      array.push(i);
    }
    return array;
  };

  const handleDateTimeChange = (newValue) => {
    setValue(newValue);
  };

  const resetTimDate = () => {
    setValue(dayjs(new Date()));
  };

  const [schedule, setSchedule] = useState({
    num: "1",
    day: "Days",
  });

  const [duration, setDuration] = useState({
    num: "1",
    day: "Hours",
  });

  const [variance, setVariance] = useState({
    num: "1",
    day: "Minutes",
  });

  const [notice, setNotice] = useState({
    num: "1",
    day: "Hours",
  });

  const setDurationNum = () => {
    setDuration((prev) => ({
      ...prev,
      num: "1",
    }));
  };

  // schedule
  const schChangeHandler = (e) => {
    const { name, value } = e.target;

    if (name === "day") {
      if (value === "Weeks" || (value === "Days" && schedule.num >= 7)) {
        setDurationOption(["Hours", "Days", "Weeks"]);
      }

      if (value === "Weeks" && duration.day === "Hours") {
        let data = generateArray(1, 30);
        setDurationData(data);
        setVarianceData(data);
      } else if (value === "Weeks" && duration.day === "Days") {
        let data;
        if (+schedule.num * 7 > 30) {
          data = generateArray(1, 30);
        } else {
          data = generateArray(1, schedule.num * 7 - 1);
        }
        setDurationData(data);
      } else if (
        (value === "Weeks" && duration.day === "Weeks") ||
        (value === "Days" && duration.day === "Days")
      ) {
        let data = generateArray(1, schedule.num - 1);
        setDurationData(data);
      }
      if (value === "Days" && duration.day === "Hours") {
        let data;
        if (schedule.num > 1) {
          data = generateArray(1, 30);
        } else {
          data = generateArray(1, 23);
        }
        setDurationData(data);
        setVarianceData(data);
      } else if (value === "Days" && duration.day === "Weeks") {
        let data;
        if (schedule.num > 7) {
          data = generateArray(1, schedule.num / 7);
        }
        setDurationData(data);
      }

      if (value === "Weeks" && duration.day === "Weeks") {
        if (duration.num > schedule.num) {
          setDuration((prev) => ({
            ...prev,
            num: "1",
          }));
        }
      }
      if (value === "Days" && duration.day === "Hours") {
        if (+schedule.num * 24 < +duration.num) {
          setDuration((prev) => ({
            ...prev,
            num: "1",
          }));
        }
        setSchedule((prev) => ({
          ...prev,
          [name]: value,
        }));
      } else if (value === "Days" && duration.day === "Weeks") {
        setSchedule((prev) => ({
          ...prev,
          [name]: value,
        }));
        if (+duration.num * 7 > +schedule.num) {
          setDuration((prev) => ({
            ...prev,
            day: "Hours",
          }));
        }
      } else if (value === "Days" && duration.day === "Days") {
        setSchedule((prev) => ({
          ...prev,
          [name]: value,
        }));
        if (+duration.num > +schedule.num) {
          setDuration((prev) => ({
            ...prev,
            num: "1",
          }));
        }
      } else {
        setSchedule((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else if (name === "num") {
      if (schedule.day === "Days" && value >= 7) {
        setDurationOption(["Hours", "Days", "Weeks"]);
      } else if (schedule.day === "Days" && value <= 7) {
        setDurationOption(["Hours", "Days"]);
      }

      if (schedule.day === "Days" && duration.day === "Hours") {
        let totalNum = value * 24;
        let data;
        if (totalNum <= 30) {
          data = generateArray(1, totalNum - 1);
        } else {
          data = generateArray(1, 30);
        }
        setDurationData(data);
        setVarianceData(data);
      } else if (schedule.day === "Days" && duration.day === "Days") {
        let data;
        if (value > 30) {
          data = generateArray(1, 30);
        } else {
          data = generateArray(1, value - 1);
        }

        setDurationData(data);
      } else if (schedule.day === "Days" && duration.day === "Weeks") {
        let data;
        if (value > 30) {
          data = generateArray(1, 30);
        } else {
          data = generateArray(1, value / 7);
        }

        setDurationData(data);
      }
      if (schedule.day === "Weeks" && duration.day === "Hours") {
        let data = generateArray(1, 30);
        setDurationData(data);
      } else if (schedule.day === "Weeks" && duration.day === "Days") {
        let data;
        if (value * 7 > 30) {
          data = generateArray(1, 30);
        } else {
          data = generateArray(1, value * 7);
        }

        setDurationData(data);
      } else if (schedule.day === "Weeks" && duration.day === "Weeks") {
        let data = generateArray(1, value);
        setDurationData(data);
      }

      if (schedule.day === "Weeks" && duration.day === "Weeks") {
        setSchedule((prev) => ({
          ...prev,
          [name]: value,
        }));
        if (+duration.num > value) {
          setDuration((prev) => ({
            ...prev,
            num: "1",
          }));
        }
      } else if (schedule.day === "Weeks" && duration.day === "Days") {
        setSchedule((prev) => ({
          ...prev,
          [name]: value,
        }));
        if (+duration.num > value * 7) {
          setDuration((prev) => ({
            ...prev,
            num: "1",
          }));
        }
      } else if (schedule.day === "Days" && duration.day === "Weeks") {
        if (+duration.num * 7 > value) {
          setDuration((prev) => ({
            ...prev,
            day: "Days",
          }));
        }
        setSchedule((prev) => ({
          ...prev,
          num: value,
        }));
      } else if (duration.day === "Hours" && schedule.day === "Days") {
        setSchedule((prev) => ({
          ...prev,
          [name]: value,
        }));
        if (+duration.num > value * 24) {
          setDuration((prev) => ({
            ...prev,
            num: "1",
          }));
        }
      } else {
        setSchedule((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    }
  };

  // another
  const duraChangeHandler = (e) => {
    const { name, value } = e.target;

    if (name === "day") {
      if (value === "Weeks") {
        let data = generateArray(1, 72);
        setNoticeData(data);
      }

      if (value === "Hours" && variance.day === "Hours") {
        let data = generateArray(1, duration.num);
        setVarianceData(data);
      }

      if (value === "Days") {
        let data;
        if (duration.num * 24 > 72) {
          data = generateArray(1, 72);
        } else {
          data = generateArray(1, duration.num * 24);
        }
        setNoticeData(data);
      }

      if (value === "Hours") {
        let data = generateArray(1, duration.num);
        setNoticeData(data);
      } else {
        let data = generateArray(1, 24);
        setNoticeData(data);
      }

      if (value === "Days" && variance.day === "Hours") {
        let data;
        if (duration.num * 24 > 60) {
          data = generateArray(1, 60);
        } else {
          data = generateArray(1, duration.num * 24);
        }
        setVarianceData(data);
      } else if (value === "Days" && variance.day === "Minutes") {
        let data = generateArray(1, 60);
        setVarianceData(data);
      }

      if (value === "Weeks") {
        let data = generateArray(1, 60);
        setVarianceData(data);
      }

      if (schedule.day === "Days" && value === "Days") {
        let data = generateArray(1, schedule.num);
        setDurationData(data);
        setDurationNum();
      } else if (schedule.day === "Days" && value === "Hours") {
        let data;
        if (schedule.num >= 2) {
          data = generateArray(1, 30);
        } else {
          data = generateArray(1, 24);
        }

        setDurationData(data);
        setDurationNum();
      } else if (schedule.day === "Days" && value === "Weeks") {
        let data = generateArray(1, schedule.num / 7);
        setDurationData(data);
        setDurationNum();
      }

      if (schedule.day === "Weeks" && value === "Hours") {
        let data = generateArray(1, 30);
        setDurationData(data);
        setDurationNum();
      } else if (schedule.day === "Weeks" && value === "Days") {
        let data;
        if (+schedule.num * 7 > 30) {
          data = generateArray(1, 30);
        } else {
          data = generateArray(1, schedule.num * 7);
        }
        setDurationData(data);
        setDurationNum();
      } else if (schedule.day === "Weeks" && value === "Weeks") {
        let data = generateArray(1, schedule.num);
        setDurationData(data);
        setDurationNum();
      }

      if (value === "Hours") {
        if (+variance.num > +duration.num) {
          setVariance((prev) => ({
            ...prev,
            num: "1",
          }));
        }
        if (+notice.num > +duration.num) {
          setNotice((prev) => ({
            ...prev,
            num: "1",
          }));
        }
      }
      // correct
      if (schedule.day === "Days" && value === "Hours") {
        setDuration((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
      // correct
      else if (schedule.day === "Days" && value === "Days") {
        if (+notice.num > +duration.num * 24) {
          setNotice((prev) => ({
            ...prev,
            num: "1",
          }));
        }
        // if (+schedule.num >= +duration.num) {
        setDuration((prev) => ({
          ...prev,
          [name]: value,
        }));
        // }
      } else if (schedule.day === "Days" && value === "Weeks") {
        setDuration((prev) => ({
          ...prev,
          [name]: value,
        }));
      } else if (schedule.day === "Weeks" && value === "Weeks") {
        if (+schedule.num >= +duration.num) {
          setDuration((prev) => ({
            ...prev,
            [name]: value,
          }));
        }
      } else if (schedule.day === "Days" && value === "Weeks") {
        if (+schedule.num >= +duration.num * 7) {
          setDuration((prev) => ({
            ...prev,
            [name]: value,
          }));
        }
      } else {
        setDuration((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else if (name === "num") {
      if (duration.day === "Days" && variance.day === "Hours") {
        let data;
        if (value * 24 > 60) {
          data = generateArray(1, 60);
        } else {
          data = generateArray(1, value * 24);
        }
        setVarianceData(data);
      } else if (duration.day === "Days" && variance.day === "Minutes") {
        let data = generateArray(1, 60);
        setVarianceData(data);
      }

      if (duration.day === "Hours" && variance.day === "Hours") {
        let data = generateArray(1, value);
        setVarianceData(data);
      }

      if (duration.day === "Days") {
        let data;
        if (value * 24 > 60) {
          data = generateArray(1, 60);
        } else {
          data = generateArray(1, value * 24);
        }

        setVarianceData(data);
      }

      if (duration.day === "Days") {
        let data;
        if (value * 24 > 72) {
          data = generateArray(1, 72);
        } else {
          data = generateArray(1, value * 24);
        }
        setNoticeData(data);
      } else if (duration.day === "Hours") {
        let data = generateArray(1, value);
        setNoticeData(data);
      }

      if (duration.day === "Hours") {
        if (value < +notice.num) {
          setNotice((prev) => ({
            ...prev,
            num: "1",
          }));
        }
      }

      if (schedule.day === "Days" && duration.day === "Hours") {
        if (+schedule.num * 24 >= value) {
          setDuration((prev) => ({
            ...prev,
            [name]: value,
          }));
        }
      } else if (schedule.day === "Days" && duration.day === "Days") {
        if (+value <= +schedule.num) {
          setDuration((prev) => ({
            ...prev,
            [name]: value,
          }));
        }
        if (value * 24 < +variance.num) {
          setVariance((prev) => ({
            ...prev,
            num: "1",
          }));
        }
      } else if (schedule.day === "Days" && duration.day === "Weeks") {
        if (+schedule.num >= value * 7) {
          setDuration((prev) => ({
            ...prev,
            [name]: value,
          }));
        }
      } else if (schedule.day === "Weeks" && duration.day === "Weeks") {
        if (value <= +schedule.num) {
          setDuration((prev) => ({
            ...prev,
            [name]: value,
          }));
        }
      } else if (schedule.day === "Weeks" && duration.day === "Days") {
        if (value <= +schedule.num * 7) {
          setDuration((prev) => ({
            ...prev,
            [name]: value,
          }));
        }
      } else {
        setDuration((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    }
  };
  // another

  const variatChangeHandler = (e) => {
    const { name, value } = e.target;

    if (name === "day") {
      if (value === "Minutes") {
        setVariance((prev) => ({
          ...prev,
          [name]: value,
        }));

        let data = generateArray(1, 60);
        setVarianceData(data);
      } else if (value === "Hours") {
        if (duration.day === "Hours") {
          let data = generateArray(1, duration.num);
          setVarianceData(data);
        } else if (duration.day === "Weeks") {
          let data = generateArray(1, 60);
          setVarianceData(data);
        } else if (duration.day === "Days") {
          let data;
          if (duration.num * 24 > 60) {
            data = generateArray(1, 60);
          } else {
            data = generateArray(1, duration.num * 24);
          }

          setVarianceData(data);
        }

        if (duration.day === "Hours") {
          if (+duration.num >= +variance.num) {
            setVariance((prev) => ({
              ...prev,
              [name]: value,
            }));
          } else {
            setVariance((prev) => ({
              ...prev,
              num: "1",
            }));
          }
        } else
          setVariance((prev) => ({
            ...prev,
            [name]: value,
          }));
      }
    } else if (name === "num") {
      if (duration.day === "Hours" && variance.day === "Hours") {
        if (+duration.num >= value) {
          setVariance((prev) => ({
            ...prev,
            [name]: value,
          }));
        } else {
          setVariance((prev) => ({
            ...prev,
            num: "1",
          }));
        }
      } else if (duration.day === "Days" && variance.day === "Hours") {
        if (value <= 24 * duration.num) {
          setVariance((prev) => ({
            ...prev,
            [name]: value,
          }));
        }
      } else {
        setVariance((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    }
  };

  // another
  const noticeChangeHandler = (e) => {
    const { name, value } = e.target;

    if (name === "num") {
      if (duration.day === "Hours") {
        if (+duration.num >= +value) {
          setNotice((prev) => ({
            ...prev,
            [name]: value,
          }));
        }
      } else if (duration.day === "Days") {
        if (+duration.num * 24 >= value) {
          setNotice((prev) => ({
            ...prev,
            [name]: value,
          }));
        }
      } else {
        setNotice((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    }
  };

  // fetch automate detail
  const fetchAutomateDetail = async () => {
    setLoading(true);
    try {
      const response = await fetch(endpoints.AUTOMATE_API, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        const formatedRes = await response.json();

        // setIsAutomate(true);
        const {
          commence,
          duration_between_tweets,
          schedule,
          random_variance,
          notice_duration,
        } = formatedRes;
        setValue(dayjs(commence));

        //  for shedule
        const regex = /(\d+)\s\d{2}:\d{2}:\d{2}/;
        const match = regex.exec(schedule);
        const extractedNumber = match ? match[1] : null;

        if (extractedNumber % 7 === 0) {
          setSchedule((prev) => ({
            ...prev,
            day: "Weeks",
            num: extractedNumber / 7,
          }));
        } else {
          setSchedule((prev) => ({
            ...prev,
            num: extractedNumber,
          }));
        }

        // this is for duration between

        const regexDuration = /^(\d*\s*)?(\d+):(\d+):(\d+)/;
        const matchDuration = regexDuration.exec(duration_between_tweets);
        const daysDuration = matchDuration
          ? parseInt(matchDuration[1], 10)
          : null;
        const hoursDuration = matchDuration
          ? parseInt(matchDuration[2], 10)
          : null;

        let totalDuration = 0;

        if (daysDuration) {
          totalDuration = daysDuration * 24;
        }
        if (hoursDuration) {
          totalDuration = totalDuration + hoursDuration;
        }
        if (totalDuration % 168 === 0) {
          setDuration((prev) => ({
            ...prev,
            day: "Weeks",
            num: totalDuration / 168,
          }));
        } else if (totalDuration % 24 === 0) {
          setDuration((prev) => ({
            ...prev,
            day: "Days",
            num: totalDuration / 24,
          }));
        } else {
          setDuration((prev) => ({
            ...prev,
            day: "Hours",
            num: totalDuration,
          }));
        }

        // this is for random onne

        const regexRandom = /^(\d*\s*)?(\d+):(\d+):(\d+)/;
        const matchRandom = regexRandom.exec(random_variance);

        const daysRandom = matchRandom ? parseInt(matchRandom[1], 10) : null;
        const hoursRandom = matchRandom ? parseInt(matchRandom[2], 10) : null;
        const minutesRandom = matchRandom ? parseInt(matchRandom[3], 10) : null;

        let totalRandom = 0;

        if (minutesRandom) {
          totalRandom = minutesRandom;
          setVariance((prev) => ({
            ...prev,
            day: "Minutes",
            num: totalRandom,
          }));
        } else {
          if (daysRandom) {
            totalRandom = daysRandom * 24;
          }

          if (hoursRandom) {
            totalRandom = totalRandom + hoursRandom;
          }

          setVariance((prev) => ({
            ...prev,
            day: "Hours",
            num: totalRandom,
          }));
        }

        //  for NOTICE
        // Splitting the time string to get hours and days
        let days = 0;
        let hours = 0;

        if (notice_duration.includes(" ")) {
          const [daysStr, time] = notice_duration.split(" ");
          days = parseInt(daysStr);
          hours = parseInt(time.split(":")[0]);
        } else {
          hours = parseInt(notice_duration.split(":")[0]);
        }

        let totalHour = 0;

        if (days) {
          totalHour = totalHour + days * 24;
        }
        if (hours) {
          totalHour = totalHour + hours;
        }

        setNotice((prev) => ({
          ...prev,
          num: totalHour,
        }));

        setIsAutomate(true);
      } else if (response.status === 204) {
        setIsAutomate(false);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  // this is another futnion
  const automateTweetHandler = async () => {
    try {
      let datetime = value.format("YYYY-MM-DDTHH:mm:ssZ");

      let scheduleTime = "";
      let duration_between_tweets = "";
      let random_variance = "";
      let notice_duration = `${notice.num}:00:00`;

      //  for shedule

      if (schedule.day === "Weeks") {
        let dayNum = schedule.num * 7;
        scheduleTime = `${dayNum} 00:00:00`;
      } else {
        scheduleTime = `${schedule.num} 00:00:00`;
      }

      // for duration

      if (duration.day === "Weeks") {
        let dayNum = duration.num * 7;
        duration_between_tweets = `${dayNum} 00:00:00`;
      } else if (duration.day === "Days") {
        duration_between_tweets = `${duration.num} 00:00:00`;
      } else {
        duration_between_tweets = `${duration.num}:00:00`;
      }

      // for variance
      if (variance.day === "Hours") {
        random_variance = `${variance.num}:00:00`;
      } else {
        random_variance = `00:${variance.num}:00`;
      }

      const formData = new FormData();
      formData.append("commence", datetime);
      formData.append("schedule", scheduleTime);
      formData.append("duration_between_tweets", duration_between_tweets);
      formData.append("random_variance", random_variance);
      formData.append("notice_duration", notice_duration);

      const response = await fetch(endpoints.AUTOMATE_API, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (response.status === 200) {
        setIsAutomate(true);
        toast.success("Successfuly Automated");
      } else {
        toast.error("Something went wrong , please try again");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // stop automate
  const stopAutomateHandler = async () => {
    try {
      const response = await fetch(endpoints.AUTOMATE_API, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status >= 204 && response.status <= 299) {
        toast.success("Successfully Stop the Automation");
        setIsAutomate(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (schedule.day === "Weeks") {
      if (+schedule.num > 1) {
        setDurationOption(["Hours", "Days", "Weeks"]);
      } else if (+schedule.num === 1) {
        setDurationOption(["Hours", "Days"]);
      }

      if (duration.day === "Hours") {
        // total hours calculate
        let data = generateArray(1, 30);
        setDurationData(data);
      } else if (duration.day === "Days") {
        let maxDays = schedule.num * 7;
        let data = generateArray(1, maxDays - 1);
        setDurationData(data);
      } else if (duration.day === "Weeks") {
        let maxWeeks = +schedule.num;
        let data = generateArray(1, maxWeeks - 1);
        setDurationData(data);
      }
    } else if (schedule.day === "Days") {
      if (duration.day === "Hours") {
        // total hours calculate
        let maxHour = schedule.num * 24;
        let data;
        if (maxHour > 30) {
          data = generateArray(1, 30);
        } else {
          data = generateArray(1, maxHour - 1);
        }
        setDurationData(data);
      } else if (duration.day === "Days") {
        let maxDays = +schedule.num;
        let data;

        if (maxDays === 1) {
          data = generateArray(1, 1);
        } else {
          data = generateArray(1, maxDays - 1);
        }
        setDurationData(data);
      }

      setDurationOption(["Hours", "Days"]);

      if (duration.day === "Weeks") {
        setDuration((prev) => ({
          ...prev,
          day: "Hours",
        }));
      }
    }
  }, [schedule]);

  useEffect(() => {
    if (duration.day === "Hours") {
      //  when schedule is in days
      if (schedule.day === "Days") {
        // total hours
        let totalHour = schedule.num * 24;
        let data;
        if (totalHour > 30) {
          data = generateArray(1, 30);
        } else {
          data = generateArray(1, totalHour - 1);
        }

        setDurationData(data);
      } else if (schedule.day === "Weeks") {
        let data = generateArray(1, 30);
        setDurationData(data);
      }

      //  for notice
      let noticeData1;
      let noticeHour = +duration.num;

      if (noticeHour === 1) {
        noticeData1 = generateArray(1, 1);
      } else if (noticeHour > 72) {
        noticeData1 = generateArray(1, 72);
      } else {
        noticeData1 = generateArray(1, noticeHour - 1);
      }

      setNoticeData(noticeData1);

      //  FOR VARIANCE
      if (variance.day === "Hours") {
        let totalHours = +duration.num;
        let data;
        if (totalHours > 60) {
          data = generateArray(1, 60);
        } else {
          data = generateArray(1, totalHours - 1);
        }

        setVarianceData(data);
      } else if (variance.day === "Minutes") {
        let totalMin = duration.num * 60;
        let data;
        if (totalMin > 60) {
          data = generateArray(1, 60);
        } else {
          data = generateArray(1, totalMin - 1);
        }

        setVarianceData(data);
      }
    } else if (duration.day === "Days") {
      //  for days
      if (schedule.day === "Days") {
        let data;

        if (+schedule.num === 1) {
          data = generateArray(1, 1);
        } else if (schedule.num > 30) {
          data = generateArray(1, 29);
        } else {
          data = generateArray(1, schedule.num - 1);
        }

        setDurationData(data);
      }

      //  for weeks
      else if (schedule.day === "Weeks") {
        let totalDays = schedule.num * 7;
        let data;
        if (totalDays > 30) {
          data = generateArray(1, 30);
        } else {
          data = generateArray(1, totalDays - 1);
        }

        setDurationData(data);
      }

      //  for notice
      let noticeTotalHour = duration.num * 24;

      let noticeData1;
      if (noticeTotalHour > 72) {
        noticeData1 = generateArray(1, 72);
      } else {
        noticeData1 = generateArray(1, noticeTotalHour - 1);
      }

      setNoticeData(noticeData1);

      //  FOR VARIANCE
      if (variance.day === "Hours") {
        let totalHours = duration.num * 24;
        let data;
        if (totalHours > 60) {
          data = generateArray(1, 60);
        } else {
          data = generateArray(1, totalHours - 1);
        }

        setVarianceData(data);
      } else if (variance.day === "Minutes") {
        let data = generateArray(1, 60);

        setVarianceData(data);
      }
    } else if (duration.day === "Weeks") {
      if (schedule.day === "Weeks") {
        let totalWeek = +schedule.num;
        let data;

        if (totalWeek > 30) {
          data = generateArray(1, 30);
        } else {
          data = generateArray(1, totalWeek - 1);
        }

        setDurationData(data);
      }

      // for notice
      let noticingData = generateArray(1, 72);
      setNoticeData(noticingData);

      //  FOR VARIANCE
      if (variance.day === "Hours") {
        let totalHours = duration.num * 7 * 24;
        let data;
        if (totalHours > 60) {
          data = generateArray(1, 60);
        } else {
          data = generateArray(1, totalHours - 1);
        }

        setVarianceData(data);
      } else if (variance.day === "Minutes") {
        let data = generateArray(1, 60);

        setVarianceData(data);
      }
    }

    if (duration.day === "Hours" && +duration.num > 1) {
      setVarianceOption(["Hours", "Minutes"]);
    } else if (duration.day !== "Hours") {
      setVarianceOption(["Hours", "Minutes"]);
    } else {
      setVarianceOption(["Minutes"]);
      setVariance((prev) => ({
        ...prev,
        day: "Minutes",
      }));
    }
  }, [duration]);

  useEffect(() => {
    if (variance.day === "Hours") {
      if (duration.day === "Hours") {
        if (duration.num === "1") {
          setVarianceOption(["Minutes"]);

          return;
        }

        setVarianceOption(["Hours", "Minutes"]);

        let data;
        let totalHour = duration.num;
        if (totalHour > 60) {
          data = generateArray(1, 60);
        } else {
          data = generateArray(1, totalHour - 1);
        }

        setVarianceData(data);
      } else if (duration.day === "Days") {
        let data;
        let totalHour = +duration.num * 24;
        if (totalHour > 60) {
          data = generateArray(1, 60);
        } else {
          data = generateArray(1, totalHour - 1);
        }

        setVarianceData(data);
      } else if (duration.day === "Weeks") {
        let data;
        let totalHour = duration.num * 7 * 24;
        if (totalHour > 60) {
          data = generateArray(1, 60);
        } else {
          data = generateArray(1, totalHour - 1);
        }

        setVarianceData(data);
      }
    } else if (variance.day === "Minutes") {
      if (duration.day === "Hours") {
        let data;
        let totalMin = duration.num * 60;
        if (totalMin > 60) {
          data = generateArray(1, 60);
        } else {
          data = generateArray(1, totalMin - 1);
        }

        setVarianceData(data);
      } else {
        let data = generateArray(1, 60);
        setVarianceData(data);
      }
    }
  }, [variance]);

  useEffect(() => {
    if (isAutomate) {
      setNoticeData([
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
        39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56,
        57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72,
      ]);

      setDurationOption(["Hours", "Days", "Weeks"]);
    }
  }, [isAutomate]);

  useEffect(() => {
    fetchAutomateDetail();
    if (!isAutomate) {
      setDurationOption(["Hours", "Days"]);
    } else {
      setVarianceOption(["Hours", "Minutes"]);
    }
  }, []);

  const currentDateTime = dayjs(new Date());

  return (
    <div className={`auRightContainer  ${showSidebar && "addBg"}`}>
      <div className={`auto_page_content overflow-hidden`}>
        <div className="auto_head overflow-hidden">
          <p>AUTOMATE</p>
        </div>

        <div className="auto_Para ">
          <p>Fully automate the scheduling of your tweets.</p>
        </div>

        <div className="autoSubPara ">
          <p>
            We will continuously populate and schedule your tweet activity based
            on your guidance.
          </p>
        </div>

        {loading ? (
          <span class="loader"></span>
        ) : (
          <div className="autoSched_box">
            {/* .    first box */}
            <div className="boxCol1">
              <p className="title ">Commencing</p>

              <div className="dateNow">
                <MobileDateTimePicker
                  // label={value.format("h:mm A dddd DD  MMMM YYYY")}
                  label={
                    value
                      ? value.format("h:mm A dddd DD MMMM YYYY")
                      : "HH:MM DD MMMM YYYY"
                  }
                  format="h:mm A dddd DD  MMMM YYYY"
                  ampm={true}
                  className="dateTimePick"
                  value={value}
                  minDateTime={currentDateTime}
                  disabled={isAutomate}
                  onChange={handleDateTimeChange}
                />

                <p
                  className="nowText"
                  onClick={() => {
                    if (!isAutomate) {
                      resetTimDate();
                    }
                  }}
                >
                  Now
                </p>
              </div>
            </div>

            {/* second box  */}
            <div className="boxCol2">
              <p className="textCom">Schedule </p>

              <div className="boxColBox">
                <div className="boxColCalen">
                  <Calendar
                    name="num"
                    values={schedule.num}
                    changeHandler={schChangeHandler}
                    accountManagement={false}
                    width={`w-[66px] h-[35px] text-[14px]`}
                    data={"6"}
                    options={scheduleData}
                    disabled={isAutomate}
                    isAutomate={isAutomate}
                  />
                  <Calendar
                    name="day"
                    values={schedule.day}
                    changeHandler={schChangeHandler}
                    accountManagement={false}
                    width={`w-[96px] h-[35px] text-[14px]`}
                    data={"Weeks"}
                    options={["Days", "Weeks"]}
                    disabled={isAutomate}
                    isAutomate={isAutomate}
                  />
                </div>

                <span className="textCom">of tweets</span>
              </div>
            </div>

            {/* third */}
            <div className="boxCol3">
              <p className="textCom pr-2 sm:w-[243px]">
                Duration between each tweet:
              </p>

              <div className="timeDate1">
                <Calendar
                  values={duration.num}
                  name="num"
                  changeHandler={duraChangeHandler}
                  accountManagement={false}
                  data={"3"}
                  width={"w-[66px] h-[35px] "}
                  options={durationData}
                  disabled={isAutomate}
                  isAutomate={isAutomate}
                />

                <Calendar
                  values={duration.day}
                  name="day"
                  changeHandler={duraChangeHandler}
                  accountManagement={false}
                  data={"days"}
                  width={"w-[96px] h-[35px]"}
                  options={durationOption}
                  disabled={isAutomate}
                  isAutomate={isAutomate}
                />
              </div>
            </div>

            {/* forth */}
            <div className="boxCol4">
              <p className="textCom w-[206px]">Random variance of schedule: </p>

              <div className="timeDate1">
                <p className="sign">+/-</p>

                <Calendar
                  values={variance.num}
                  name="num"
                  changeHandler={variatChangeHandler}
                  accountManagement={false}
                  data={"6"}
                  width={"w-[66px] h-[35px]"}
                  options={variationData}
                  disabled={isAutomate}
                  isAutomate={isAutomate}
                />

                <Calendar
                  values={variance.day}
                  name="day"
                  changeHandler={variatChangeHandler}
                  accountManagement={false}
                  data={"hours"}
                  width={"w-[96px] h-[35px]"}
                  options={varianceOption}
                  disabled={isAutomate}
                  isAutomate={isAutomate}
                />
              </div>
            </div>

            {/* fivth */}
            <div className="boxCol5">
              <p className="textCom w-[151px]">System will give you</p>

              <div className="timeDate2">
                <Calendar
                  values={notice.num}
                  name="num"
                  changeHandler={noticeChangeHandler}
                  accountManagement={false}
                  data={"6"}
                  width={"w-[66px] h-[35px]"}
                  options={NoticeData}
                  disabled={isAutomate}
                  isAutomate={isAutomate}
                />

                <Calendar
                  values={notice.day}
                  name="day"
                  changeHandler={noticeChangeHandler}
                  accountManagement={false}
                  data={"hours"}
                  width={"w-[96px] h-[35px]"}
                  options={["Hours"]}
                  disabled={isAutomate}
                  isAutomate={isAutomate}
                />

                <span className="textCom">
                  notice before first tweet is posted
                </span>
              </div>
            </div>

            <button
              onClick={() => {
                if (isAutomate) {
                  stopAutomateHandler();
                } else {
                  automateTweetHandler();
                }
              }}
              className="autoBtn"
            >
              {isAutomate ? (
                <span>STOP AUTOMATION</span>
              ) : (
                <span>AUTOMATE</span>
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default AutomatePage;
