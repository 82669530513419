import React, { useContext, useEffect, useState  } from "react";
import "./popup.css";
import { AppContext } from "../../Context/AppContext";
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

import dayjs from 'dayjs';
import { useSelector } from "react-redux";
import { endpoints } from "../../services/api";
import toast from "react-hot-toast";


function SchdulePopup() {
  const { setShowSchedulePopup , schedulePopupData , setSchedulePopupData  ,fetchAllShedule} = useContext(AppContext);
 const {accessToken} = useSelector((state)=>state.auth);

 const [loading , setLoading] = useState(false);

 const [shLoading , setShloading] = useState(false);

 const [popLoading , setPopLoading] = useState(false);

 const [state , setState] = useState("denie");

 const [value , setValue] = useState(dayjs(schedulePopupData?.date_time))


 const updateSchedule = async()=>{

  setLoading(true);
try{

  let date_time =value.format("YYYY-MM-DDTHH:mm:ssZ");

  const {id ,tweet_generated } = schedulePopupData;

  const response = await fetch(endpoints.FETCH_ALL_SCHEDULE_API , {

    method:"POST",
    headers: {
      "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({id , tweet_generated , date_time})
})


if(response.status === 200){
  toast.success("Successfuly Schedule");
  fetchAllShedule();
  setShowSchedulePopup(false);
}
else {
  toast.error("Please try again");
}

} catch(error){
  console.log(error);
}

setLoading(false);
 }

 const handleTextareaChange = (event) => {
  setSchedulePopupData(prevData => ({
    ...prevData,
    tweet_generated: event.target.value
  }));
};


const handleDateTimeChange = (newValue) => {
  setValue(newValue); 
};

const currentDateTime = dayjs(new Date());


const regenerateHandler = async()=>{
  setShloading(true);
  try{

    const {id , tweet_generated , date_time , posting_started , prompt } = schedulePopupData;


    const response = await fetch(endpoints.REGENERATE_SCHEDULE_API , {

      method:"POST",
      headers: {
        "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({id  , tweet_generated
        })
  })

  const formatedRes = await response.json();

  if(response.status === 200){
    const {tweet_generated} = formatedRes;
  setSchedulePopupData({
    id ,  tweet_generated , date_time , posting_started , prompt
  })
  }

  } catch(error){
    console.log(error);
  }
  setShloading(false);
  setState("denie");
  setPopLoading(false);
}


useEffect(()=>{

  if(state === "running"){
    console.log("stae ",state);
    setPopLoading(true);
  setSchedulePopupData(null);
   regenerateHandler();
  }

},[state])

  return (
    <div className="popupWrap">

<div className="GenpupupNav">

<p
  onClick={() => setShowSchedulePopup(false)}
  className="icon cursor-pointer"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M19.5 3H5.5C4.39543 3 3.5 3.89543 3.5 5V19C3.5 20.1046 4.39543 21 5.5 21H19.5C20.6046 21 21.5 20.1046 21.5 19V5C21.5 3.89543 20.6046 3 19.5 3Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.5 9L9.5 15"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.5 9L15.5 15"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

</p>
</div>

      {
       popLoading ?
       <span className="loadersss"></span>
       :

    <>
   
      <div className="firstTextPop">
        
        <textarea name="" id="" cols="30" rows="10"  value={schedulePopupData?.tweet_generated} onChange={handleTextareaChange} />
               </div>

      <div className="popupSchedule">
        <p className="Text">Schedule (GMT+8)</p>

        <div className="dateTimePicker">

     
        <MobileDateTimePicker
                    label="10:30AM Friday 14th June 2024"
                    format="h:mm A dddd DD  MMMM YYYY"
                    ampm={true}
                    className="dateTimePick"
                       value={value}
                       minDateTime={currentDateTime}
                       onChange={handleDateTimeChange}
                  />
        

    </div>

      </div>

      </>

}

      <div className="reBtn">
        <button disabled={shLoading} onClick={()=>{
          if(state !== "running"){
                setState("running");
          }
        }} className="reshbtn">
        
          <span>RE-GENERATE</span>
        
        </button>

 
        <button onClick={updateSchedule} className="scheduleBtn">
      {loading? <span className="loading"></span>: <span>SCHEDULE</span>}
        </button>
      </div>
    </div>
  );
}
export default SchdulePopup;
