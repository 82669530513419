import "./passwordResetConfirm.css"
import Header from "../Components/Common/Header"
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { endpoints } from "../services/api";

function PasswordResetConfirm(){
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  const tokenSpace = token?.replace(/\s/g, "");

  console.log('tokeenspa',tokenSpace);

    const [isButtonDisabled , setisButtonDisabled] = useState(false);

    const navigate = useNavigate();

   const [formData , setFormData] = useState({
    password:"",
      confirmPassword:""
  })

  const changeHandler = (e)=>{
   e.preventDefault();

   const {value , name} = e.target;


   setFormData((prev)=>({
       ...prev ,
       [name]:value
   }))
}

const submitHandler = async()=>{

  
  
  if(formData.confirmPassword !== formData.password){
    return toast.error("Password and confirm password do not match");
  }

  setisButtonDisabled(true);
  
   const toastId = toast.loading("Loading...");

   try{

       const response = await fetch(endpoints.CHANGE_PASSWORD_API , {
           method: "POST",
           
           headers: {
             "content-type": "application/json",
           },

           body: JSON.stringify({password:formData.password , token:tokenSpace}),
         });
            
         console.log("resp" ,response);
       
          if(response.status >= 200  && response.status <= 299){
            
            toast.success("Successsfully changed the Password");

            setTimeout(()=>{
              navigate("/login");

            },1000)
            
         }
         else if(response.status === 400){
          toast.error("Password is too short. Must contain 12 characters");
         }
         else if(response.status === 405){
            toast.error("Not Allowed");
         }
        else if(response.status === 401){
          toast.error("you are not authorized to access ");
         }
         else if(response.status === 404){
          toast.error("reset Password URL is not valid");
         }     

   } catch(error){
       console.log(error);
       
   }
   toast.dismiss(toastId);

   setisButtonDisabled(false);
}

useEffect(()=>{
 if(!token){
  navigate("/");
 }
},[])

const [passwordMatch , setPasswordMatch] =useState(null);

useEffect(()=>{

  if(formData.confirmPassword === "" || formData.password === ""){
    setPasswordMatch(null);
  }

  else if(formData.confirmPassword === formData.password){
      setPasswordMatch(true);
   }
   else {
    setPasswordMatch(false);
   }

},[formData.confirmPassword  , formData.password])


    return (
        <div className="loginWrap">

        <Header showBtn={false} showSmBtn={false} />

         <div className="loginContain overflow-x-hidden">

            <div className="log_HP">

              <h2 className="log_head">Change Password</h2>
             
          
            </div>


             <form onSubmit={(e)=>{
              e.preventDefault();
              submitHandler();
             }} >
                 
                 <div className="logemailCon">
                    <label htmlFor="">New Password</label>
                    <input required name="password" value={formData.password} onChange={changeHandler} type="password" placeholder="At least 12 characters with numbers and special characters" />
                 </div>

                 <div className="logemailCon">
                    <label htmlFor="">Confirm Password</label>
                    <input required name="confirmPassword" value={formData.confirmPassword} onChange={changeHandler} type="password"  />
                 </div>

                {
                  passwordMatch === false && 
                  <div className="notMatch">
                    
                  <p >Password do not matching</p>
                  </div>
                 
                }

                 <button disabled={isButtonDisabled} type="submit" className="loginBtn">
                    <span>Submit</span>
                 </button>
                
             </form>
         </div>

        </div>
    )
}

export default PasswordResetConfirm;