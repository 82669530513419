import "./Dashboard.css";
import Header from "../Components/Common/Header";
import SideBar from "../Components/Common/Sidebar";
import Sidebar2 from "../Components/Common/Sidebar2"
import { useContext } from "react";
import { AppContext } from "../Context/AppContext";
import AdminGenerate from "./AdminGenerate";
import AccountManagement from "./AccountManagement";
import AccountFeedPage from "./AccountFeedPage";
import AutomatePage from "./AutomatePage";
import GeneratePage from "./GeneratePage";
import SchedulePost from "./SchedulePost";
import Topics from "./Topics";
import PopupData from "../Components/Common/PopupData";
import GenPopup from "../Components/Common/GenPopup";
import DeletePopup from "../Components/Common/DeletePopup";
import ReportPopup from "../Components/Common/ReportPopup"
import { useSelector } from "react-redux";
import Connection from "./Connection";
import ReportLogs from "./ReportLogs"
import SchdulePopup from "../Components/Common/PopupData";


function Dashboard() {
  const {showschedulePostPopup , showGenerPopup ,currentPage , showDeletePopup , showReportPopup  } = useContext(AppContext);
  const { is_superUser, is_staff ,is_twitter_authorized} = useSelector(
    (state) => state.auth
  );
  
  return (
    <div className={`dashboardWrap ${(showDeletePopup || showReportPopup) && "hideOver"}`}>
        {
          is_twitter_authorized === true ?
          <Header showBtn={false} showLogoutBtn={true} showSmBtn={true} />
:          <Header showBtn={false} />
          
        }
     

      <div className="dashboardContain">

        {
           (is_superUser) ?
            <SideBar  />   
            :
            <>
            {
              is_twitter_authorized === true ?
              <SideBar />
              :
              <>
              <Sidebar2 />
              </>
            }

            </>     
        }
  
             
             {
             ((is_superUser) || (is_twitter_authorized) ) ? 
<>
     {currentPage == "PROMPT MANAGEMENT" && <AdminGenerate />}
        {currentPage == "ACCOUNT MANAGEMENT" && <AccountManagement />}
        {currentPage == "SCHEDULED" && <SchedulePost />}
        {currentPage == "AUTOMATE" && <AutomatePage />}
        {currentPage == "GENERATE" && <GeneratePage />}
        {currentPage == "TOPICS" && <Topics />}
        {currentPage == "ACCOUNT FEEDS" && <AccountFeedPage />}
        {  currentPage =="REPORT LOGS" && <ReportLogs /> }
        
        </>
        :
            <Connection />
        

             }

      
      </div>

      {
  showschedulePostPopup && 

  
        <div className="ScpopupLayer">

                    <SchdulePopup />

        </div>

}


{
  showGenerPopup &&
  
  <div className="genPopupLayer">
  <GenPopup />
</div>
}

{
  showDeletePopup &&  
          <div className="deletePopupLayer">
          <DeletePopup />

</div>

}

{
  showReportPopup && 
       
          <div className="reportPopupLayer">
          <ReportPopup />

</div>

}


   
    </div>
  );
}
export default Dashboard;
