import "./login.css"
import Header from "../Components/Common/Header"
import { useContext, useState } from "react";
import { endpoints } from "../services/api";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { setAccessToken , setRefreshToken , setSuperUser , setStaff, setIsApproved ,setIsTwitterAuth } from "../reducer/slices/authSlice";
import { useDispatch } from "react-redux";
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { AppContext } from "../Context/AppContext";


function Login(){


    const { covertBooleanDecrpte3d , booleanEncrpt ,   textEncrpted ,textdecrpted} = useContext(AppContext);
    const dispatch = useDispatch();

    const [isButtonDisabled , setisButtonDisabled] = useState(false);

    const navigate = useNavigate();

   const [formData , setFormData] = useState({
      email:"",
      password:""
  })

  const changeHandler = (e)=>{
   e.preventDefault();

   const {value , name} = e.target;


   setFormData((prev)=>({
       ...prev ,
       [name]:value
   }))
}

const checkUserHandler = async(access , refresh)=>{

    try{

        const response = await fetch(endpoints.CHECK_USERDETAILS_API , {

            method:"GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${access}`,
              },
        })

        const formattedResponse = await response.json();
        
        if(response.status === 200){

            const {is_staff , is_superuser , is_approved , is_twitter_authorized} = formattedResponse;
            
            dispatch(setSuperUser(is_superuser))
            dispatch(setStaff(is_staff));
            dispatch(setIsTwitterAuth(is_twitter_authorized));
            dispatch(setIsApproved(is_approved));
            dispatch(setAccessToken(access));
            dispatch(setRefreshToken(refresh));




        localStorage.setItem("autoAiRefreshToken" , JSON.stringify(textEncrpted(refresh)));
        localStorage.setItem("autoAiaccessToken" , JSON.stringify(textEncrpted(access)));
        localStorage.setItem("autoAiis_approved" , JSON.stringify(booleanEncrpt(is_approved)));
        localStorage.setItem("autoAiis_twitter_authorized" , JSON.stringify(booleanEncrpt(is_twitter_authorized)));
        localStorage.setItem("autoAiis_staff" , JSON.stringify(booleanEncrpt(is_staff)));
        localStorage.setItem("autoAiis_superuser" , JSON.stringify(booleanEncrpt(is_superuser)));



           if(is_staff || is_superuser){
            navigate("/dashboard")
           } else if(!is_approved){
                 navigate("/requestRecieve")
           }
           else if(!is_twitter_authorized){
            navigate("/connection")
           }
           else {
            navigate("/dashboard");
           }

             toast.success("Successfully login")

        }
        else if(response.status === 401 ){
                const { detail , messages} = formattedResponse;

                if(messages?.message){
                toast.error(messages?.message);
                }

                else if(detail){
                    toast.error(detail)
                }
                else{
                     toast.error("Something went wrong , please try again")
                }
               
                
        }
        else {
            toast.error("Something went wrong , please try again");
        }

    } catch(error){
        console.log("er",error);
    }

}

const submitHandler = async(e)=>{

    setisButtonDisabled(true);

   e.preventDefault();

   const toastId = toast.loading("Loading...");

   try{

       const response = await fetch(endpoints.LOGIN_API , {
           method: "POST",
           
           headers: {
             "content-type": "application/json",
           },

           body: JSON.stringify(formData),
         });
     

         const formattedResponse = await response.json();     
         

          if(response.status === 200){
           
            const {access , refresh } = formattedResponse;
            checkUserHandler(access , refresh);
         }
        else if(response.status === 401){
            const {detail} = formattedResponse;
            toast.error(detail);
         }
      else {
        toast.error("Something went wrong , please try again");
      }
         

   } catch(error){
       console.log(error);
       
   }
   toast.dismiss(toastId);

   setisButtonDisabled(false);
}


const [showPassword , setShowPassword] = useState(false);

    return (
        <div className="loginWrap">

        <Header showBtn={false} showSmBtn={false} />

         <div className="loginContain overflow-x-hidden">

            <div className="log_HP">

 
              <h2 className="log_head">Login</h2>
             
          
            </div>


             <form onSubmit={submitHandler} >
                 
                 <div className="logemailCon">
                    <label htmlFor="">Email</label>
                    <input required name="email" value={formData.email} onChange={changeHandler} type="email" placeholder="john@doe.com" />
                 </div>

                 <div className="logemailCon">
                    <label htmlFor="">Password</label>

                    <div className="inputPassWrap">

                    <input required name="password" value={formData.password} onChange={changeHandler} type={showPassword?"text":"password"}  />

                            <span>{!showPassword?<FaEyeSlash className="showPassword" onClick={()=>setShowPassword(true)} /> : <FaEye  className="showPassword" onClick={()=>setShowPassword(false)}  />}</span>
                    </div>

                 </div>

                 <button disabled={isButtonDisabled} type="submit" className="loginBtn">
                    <span>Login</span>
                 </button>
                
                 <div  className="forPassLogin">
                    <p onClick={() => navigate("/change-password", { state: { isDirected:true } })} className="cursor-pointer">Forgot Password</p>

                    <p onClick={()=>navigate("/request-access")} className="cursor-pointer newUser" >New User? Signup</p>
                 </div>

             </form>
         </div>

        </div>
    )
}

export default Login;