import { useContext, useEffect, useState } from "react";
import "./schedulePost.css";
import { AppContext } from "../Context/AppContext";
import { useSelector } from "react-redux";
import { endpoints } from "../services/api";
import toast from "react-hot-toast";
import dayjs from "dayjs";

function AccountFeedPage() {
  const {
    setShowSchedulePopup,
    fetchAllShedule,
    setSchedulePopupData,
    data,
    scLoading,
    
  } = useContext(AppContext);

  const { accessToken } = useSelector((state) => state.auth);

  const deleteSchedule = async (scId) => {
    const id = scId;
    const toastId = toast.loading("Loading...");
    try {
      const response = await fetch(endpoints.FETCH_ALL_SCHEDULE_API, {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ id }),
      });


      if (response.status === 204) {
        toast.success("Successfuly Deleted");
        fetchAllShedule();
      } else {
        toast.error("Failed to delete schedule");
      }
    } catch (error) {
      console.log("error", error);
    }

    toast.dismiss(toastId);
  };

  useEffect(() => {
    fetchAllShedule();
  }, []);


  return (
    <div className="scherightContain overflow-x-hidden">
      <div className="shcPoContent">
        <div className="shc_head">
          <p>SCHEDULED POSTS</p>
        </div>

        <div className="sch_Para ">
          <p>View and Edit your schedule posts</p>
        </div>

         {
          data?.next_tweet_generation && 
        <div className="sch_Para2 ">
          <p>
            The system will generate a new set tweet of tweets on:{" "}
            <span className="accEdit">
  {new Date(data?.next_tweet_generation).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })} {new Date(data?.next_tweet_generation).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })}
</span>

          </p>
        </div>
        }

        <div className="shcManBlock">
          {scLoading ? (
            <span class="loader"></span>
          ) : data?.data?.length > 0   ? (
            data?.data?.map((tw, index) => (
              <div
                key={index}
                className={`singleTw ${
                  index % 2 === 0 ? "AddEvenTw" : "AddOddTw"
                }`}
              >
                <p
                  onClick={() => {
                    setSchedulePopupData(tw);
                    setShowSchedulePopup(true);
                  }}
                  className="twDate"
                >
                   {dayjs(tw?.date_time).format("h:mmA D MMMM")}
                </p>
                <p
                  onClick={() => {
                    setSchedulePopupData(tw);
                    setShowSchedulePopup(true);
                  }}
                  className="twTitl"
                >
                {tw?.tweet_generated?.split(" ")?.length < 8 ? tw?.tweet_generated : `${tw?.tweet_generated?.split(" ")?.slice(0, 7)?.join(" ")}...`}
                </p>
                <p
                  onClick={() => deleteSchedule(tw?.id)}
                  className="crossIcon cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="19"
                    viewBox="0 0 21 19"
                    fill="none"
                  >
                    <path
                      d="M7.875 5.54167L9.625 9.5L7.875 13.4583H9.625L10.5 11.4792L11.375 13.4583H13.125L11.375 9.5L13.125 5.54167H11.375L10.5 7.52083L9.625 5.54167H7.875ZM4.375 2.375H16.625C17.0891 2.375 17.5342 2.54181 17.8624 2.83875C18.1906 3.13568 18.375 3.53841 18.375 3.95833V15.0417C18.375 15.4616 18.1906 15.8643 17.8624 16.1613C17.5342 16.4582 17.0891 16.625 16.625 16.625H4.375C3.91087 16.625 3.46575 16.4582 3.13756 16.1613C2.80937 15.8643 2.625 15.4616 2.625 15.0417V3.95833C2.625 3.53841 2.80937 3.13568 3.13756 2.83875C3.46575 2.54181 3.91087 2.375 4.375 2.375Z"
                      fill="#F34A4A"
                    />
                  </svg>
                </p>
              </div>
            ))
          ) : (
            <span className="font-[600] mx-auto">No data found</span>
          )}
          {}
        </div>
      </div>
    </div>
  );
}

export default AccountFeedPage;
